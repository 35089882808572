* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: "Roboto", sans-serif
}

body {
    margin: 0 auto
}

.m-5-auto {
    margin: 5rem auto
}

.primary-button {
    margin-top: 5rem;
    margin-right: 1rem;
    padding: .6rem;
    width: 10rem;
    background: #222;
    border: none;
    color: #fff;
    font-size: 1.2rem;
    transition: all .5s;
    cursor: pointer;
    text-transform: capitalize
}


/***    Login Page     ***/
h2 {
    font-weight: 300
}

form {
    display: inline-block;
    background: #f3f3f3;
    border: 5px solid #ddd;
    border-radius: 2px;
    padding: 5rem;
    margin: 1rem;
}

form label {
    float: left;
    font-size: 14px;
    margin: 0;
    padding: 0
}

.right-label {
    float: right;
    cursor: pointer
}

input {
    width: 25rem;
    padding: 1rem;
    border-radius: 5px;
    outline: none;
    border: none
}

#sub_btn {
    display: block;
    width: 100%;
    padding: 1rem;
    border: none;
    background: #222;
    color: #fff;
    border-radius: 3px;
}

#sub_btn:hover {
    background: #333;
    transition: all .5s
}

footer p {
    margin: 0;
    font-size: .9rem
}

