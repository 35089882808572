footer {
	min-height: 120px;
	padding: 50px 50px 50px 50px;
	background: black;
	background: -moz-linear-gradient(-45deg, black 0%, rgb(172, 87, 172) 100%);
	background: -webkit-linear-gradient(-45deg, black 0%, rgb(172, 87, 172) 100%);
	background: linear-gradient(135deg, black 0%, rgb(172, 87, 172) 100%);
 	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='black', endColorstr='#5fc3e4', GradientType=1 );
	box-sizing: border-box;

}
footer p {
	
	color: #FFFFFF;
	padding: 0px 0px 10px 350px ;
}

#rights{
	font-size: medium;
}

.logo .icon {
	color: #FFFFFF;
}

.logo {
	padding: 0px 0px 0px 450px ;
}

#rights{
	font-size: medium;
}