@import url("https://fonts.googleapis.com/css?family=Open+Sans:400,300");

/* Base Styles
********************************************************************* */
html {
	font-size: 62.5%;
}
body {
	font-size: 1.5em;
	line-height: 1.6;
	font-weight: 400;
	font-family: 'Open Sans', Helvetica, Arial, sans-serif;
	color: #222;
}
/* Grid
********************************************************************* */  
.container {
	position: relative;
	width: 100%;
	max-width: 960px;
	margin: 0 auto;
	padding: 0 20px;
	box-sizing: border-box;
}
.column, .columns {
	width: 100%;
	float: left;
	box-sizing: border-box;
}

.animate__animated.animate__fadeIn {
    --animate-duration: 3s;
  }

/* For devices larger than 400px */
@media (min-width: 400px) {
.container {
	width: 85%;
	padding: 0;
}
}

/* For devices larger than 550px */
@media (min-width: 550px) {
.container {
	width: 80%;
}
.column,  .columns {
	margin-left: 4%;
}
.column:first-child,  .columns:first-child {
	margin-left: 0;
}
.one.column,  .one.columns {
	width: 4.66666666667%;
}
.two.columns {
	width: 13.3333333333%;
}
.three.columns {
	width: 22%;
}
.four.columns {
	width: 30.6666666667%;
}
.five.columns {
	width: 39.3333333333%;
}
.six.columns {
	width: 48%;
}
.seven.columns {
	width: 56.6666666667%;
}
.eight.columns {
	width: 65.3333333333%;
}
.nine.columns {
	width: 74.0%;
}
.ten.columns {
	width: 82.6666666667%;
}
.eleven.columns {
	width: 91.3333333333%;
}
.twelve.columns {
	width: 100%;
	margin-left: 0;
}
.one-third.column {
	width: 30.6666666667%;
}
.two-thirds.column {
	width: 65.3333333333%;
}
.one-half.column {
	width: 48%;
}
/* Offsets */
.offset-by-one.column,  .offset-by-one.columns {
	margin-left: 8.66666666667%;
}
.offset-by-two.column,  .offset-by-two.columns {
	margin-left: 17.3333333333%;
}
.offset-by-three.column,  .offset-by-three.columns {
	margin-left: 26%;
}
.offset-by-four.column,  .offset-by-four.columns {
	margin-left: 34.6666666667%;
}
.offset-by-five.column,  .offset-by-five.columns {
	margin-left: 43.3333333333%;
}
.offset-by-six.column,  .offset-by-six.columns {
	margin-left: 52%;
}
.offset-by-seven.column,  .offset-by-seven.columns {
	margin-left: 60.6666666667%;
}
.offset-by-eight.column,  .offset-by-eight.columns {
	margin-left: 69.3333333333%;
}
.offset-by-nine.column,  .offset-by-nine.columns {
	margin-left: 78.0%;
}
.offset-by-ten.column,  .offset-by-ten.columns {
	margin-left: 86.6666666667%;
}
.offset-by-eleven.column,  .offset-by-eleven.columns {
	margin-left: 95.3333333333%;
}
.offset-by-one-third.column,  .offset-by-one-third.columns {
	margin-left: 34.6666666667%;
}
.offset-by-two-thirds.column,  .offset-by-two-thirds.columns {
	margin-left: 69.3333333333%;
}
.offset-by-one-half.column,  .offset-by-one-half.columns {
	margin-left: 52%;
}
}
/* Typography
********************************************************************* */
h1, h2, h3, h4, h5, h6 {
	margin-top: 0;
	margin-bottom: 2rem;
	font-weight: 300;
}
h1 {
	font-size: 4.0rem;
	line-height: 1.2;
	letter-spacing: -.1rem;
}
h2 {
	font-size: 3.6rem;
	line-height: 1.25;
	letter-spacing: -.1rem;
}
h3 {
	font-size: 3.0rem;
	line-height: 1.3;
	letter-spacing: -.1rem;
}
h4 {
	font-size: 2.4rem;
	line-height: 1.35;
	letter-spacing: -.08rem;
}
h5 {
	font-size: 1.8rem;
	line-height: 1.5;
	letter-spacing: -.05rem;
}
h6 {
	font-size: 1.5rem;
	line-height: 1.6;
	letter-spacing: 0;
}

/* Larger than phablet */
@media (min-width: 550px) {
h1 {
	font-size: 5.0rem;
}
h2 {
	font-size: 4.2rem;
}
h3 {
	font-size: 3.6rem;
}
h4 {
	font-size: 3.0rem;
}
h5 {
	font-size: 2.4rem;
}
h6 {
	font-size: 1.5rem;
}
}
p {
	margin-top: 0;
}
/* Links
********************************************************************* */
a {
	color: rgb(172, 87, 172);
}
a:hover {
	color: rgb(172, 87, 172);
}

/* Misc
********************************************************************* */

.icon {
	padding-right: 15px;
	color: black;
}
.block {
	width: 50px;
	height: 2px;
	background: black; /* Old browsers */
	background: -moz-linear-gradient(-45deg, black 0%, rgb(172, 87, 172) 100%);
	background: -webkit-linear-gradient(-45deg, black 0%, rgb(172, 87, 172) 100%);
	background: linear-gradient(135deg, black 0%, rgb(172, 87, 172) 100%);
	margin-bottom: 50px;
}
.fsmenublock{
	width: 100px;
	height: 2px;
	background: black; /* Old browsers */
	background: -moz-linear-gradient(-45deg, black 0%, rgb(172, 87, 172) 100%);
	background: -webkit-linear-gradient(-45deg, black 0%, rgb(172, 87, 172) 100%);
	background: linear-gradient(135deg, black 0%, rgb(172, 87, 172) 100%);
	margin-bottom: 50px;
}

.csmenublock{
	width: 125px;
	height: 2px;
	background: black; /* Old browsers */
	background: -moz-linear-gradient(-45deg, black 0%, rgb(172, 87, 172) 100%);
	background: -webkit-linear-gradient(-45deg, black 0%, rgb(172, 87, 172) 100%);
	background: linear-gradient(135deg, black 0%, rgb(172, 87, 172) 100%);
	margin-bottom: 50px;
}

.tnmenublock{
	width: 80px;
	height: 2px;
	background: black; /* Old browsers */
	background: -moz-linear-gradient(-45deg, black 0%, rgb(172, 87, 172) 100%);
	background: -webkit-linear-gradient(-45deg, black 0%, rgb(172, 87, 172) 100%);
	background: linear-gradient(135deg, black 0%, rgb(172, 87, 172) 100%);
	margin-bottom: 50px;
}

.lmenublock{
	width: 50px;
	height: 2px;
	background: black; /* Old browsers */
	background: -moz-linear-gradient(-45deg, black 0%, rgb(172, 87, 172) 100%);
	background: -webkit-linear-gradient(-45deg, black 0%, rgb(172, 87, 172) 100%);
	background: linear-gradient(135deg, black 0%, rgb(172, 87, 172) 100%);
	margin-bottom: 50px;
}

.cmenublock{
	width: 70px;
	height: 2px;
	background: black; /* Old browsers */
	background: -moz-linear-gradient(-45deg, black 0%, rgb(172, 87, 172) 100%);
	background: -webkit-linear-gradient(-45deg, black 0%, rgb(172, 87, 172) 100%);
	background: linear-gradient(135deg, black 0%, rgb(172, 87, 172) 100%);
	margin-bottom: 50px;
}

.lsmenublock{
	width: 100px;
	height: 2px;
	background: black; /* Old browsers */
	background: -moz-linear-gradient(-45deg, black 0%, rgb(172, 87, 172) 100%);
	background: -webkit-linear-gradient(-45deg, black 0%, rgb(172, 87, 172) 100%);
	background: linear-gradient(135deg, black 0%, rgb(172, 87, 172) 100%);
	margin-bottom: 50px;
}

.ppmenublock{
	width: 65px;
	height: 2px;
	background: black; /* Old browsers */
	background: -moz-linear-gradient(-45deg, black 0%, rgb(172, 87, 172) 100%);
	background: -webkit-linear-gradient(-45deg, black 0%, rgb(172, 87, 172) 100%);
	background: linear-gradient(135deg, black 0%, rgb(172, 87, 172) 100%);
	margin-bottom: 50px;
}

.lomenublock{
	width: 60px;
	height: 2px;
	background: black; /* Old browsers */
	background: -moz-linear-gradient(-45deg, black 0%, rgb(172, 87, 172) 100%);
	background: -webkit-linear-gradient(-45deg, black 0%, rgb(172, 87, 172) 100%);
	background: linear-gradient(135deg, black 0%, rgb(172, 87, 172) 100%);
	margin-bottom: 50px;
}