#firmprofile {
	padding: 10px 0 50px 0;
}

#hello {
    font-size: 50px;
}

.skills-icons {
    font-size: 50px;
    padding: 50px 175px 0 175px;
    
 }
